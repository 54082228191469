/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import MenuSidebar from '../../components/sidebar/menuSidebar';
import "../../assets/css/tab.css";
export default function Biblioteca() {

  const biblioteca = ["certificacion", "talleres", "ensayos", "chas", "rtoe"];

  const [PantallasPermisos, setPantallasPermisos] = React.useState([]);
  const [Biblioteca, setBiblioteca] = React.useState([]);
  const [BibliotecaExt, setBibliotecaExt] = React.useState([]);
  const [Activo, setActivo] = React.useState("");
  const [SubActivo, setSubActivo] = React.useState("");
  const [SubActivoExt, setSubActivoExt] = React.useState("");


  const cargarPerfil = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'perfil': auth.id }),
      });
      const json = await resp.json();
      biblioteca.map(function (element) {
        if (json.find(arreglo => arreglo['nombre'] === element)) {
        } else {
          try {
            const seccion = document.getElementById(element);
            seccion.remove();
            const tab = document.getElementById(element);
            tab.remove();
            const lab = document.getElementById(element + "lab");
            lab.remove();
          } catch (error) {

          }
        }
      }
      );
      document.getElementById("menu").style.visibility = "visible";
      document.getElementById("biblio").style.visibility = "visible";

    } catch (error) {
      console.log(error);
    }
  }

  const cargarBiblioteca = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': Activo }),
      });
      const json = await resp.json();
      setBiblioteca(json);

    } catch (error) {
      console.log(error);
    }
  };

  const cargarBibliotecaExt = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': Activo }),
      });
      const json = await resp.json();
      setBibliotecaExt(json);

    } catch (error) {
      console.log(error);
    }
  };

  const cargarSubBiblioteca = async (url, nombre) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': Activo + '/' + nombre }),
      });
      const json = await resp.json();
      setBiblioteca(json);

    } catch (error) {
      console.log(error);
    }
  };

  const cargarSubBibliotecaExt = async (url, nombre) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'carpeta': Activo + '/' + nombre }),
      });
      const json = await resp.json();
      setBibliotecaExt(json);

    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (PantallasPermisos.length === 0) {
      try {
        cargarPerfil(process.env.REACT_APP_BACKEND_URL + "/obtenerPermisosBiblioteca.php");
      } catch (error) {

      }
    }
    if (Activo.length > 0) {
      try {

        cargarBiblioteca(process.env.REACT_APP_BACKEND_URL + "/cargar.php");
      } catch (error) {

      }
    }
    if (Activo.length > 0) {
      try {

        cargarBibliotecaExt(process.env.REACT_APP_BACKEND_URL + "/cargarExt.php");
      } catch (error) {

      }
    }
  }, [PantallasPermisos, Activo])

  const handleClick = (e) => {
    document.getElementById("btnCargar").style.visibility = "visible";

    setActivo(e.target.id);
  };

  function ObtenerListadoBiblioteca(props) {
    let carpetas = [];
    let archivos = [];

    if (Activo === (props.Nombre)) {

      const urlSearchParams = new URLSearchParams(window.location.search);
      const vari = urlSearchParams.get("namefolder");
      if (vari === "") {
        setSubActivo("");
      }
      for (const property in Biblioteca) {
        if (`${Biblioteca[property].type}` === "Carpeta") {
          carpetas.push(`${Biblioteca[property].name}`);
        }
        else {
          archivos.push(`${Biblioteca[property].name}`);
        }

      }
    }
    try {
      if (props.currentTarget.id !== undefined) {
        setSubActivo(props.currentTarget.id);
        cargarSubBiblioteca(process.env.REACT_APP_BACKEND_URL + "/cargar.php", props.currentTarget.id)
        for (const property in Biblioteca) {
          {
            archivos.push(`${Biblioteca[property].name}`);
          }

        }
      } else {

      }
    } catch (error) {
    }

    return (
      <ul className="directory-list listado">
        {SubActivo !== "" ? <li  >
          <a onClick={ObtenerListadoBiblioteca} href="#"  >
            <i className="fa fa-arrow-circle-left  fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text" >
              volver
            </span>
          </a>
        </li> : null}
        {carpetas.map(name => <li key={name}>
          <a onClick={ObtenerListadoBiblioteca} Nombre={Activo + '/' + name} id={name} href={"#?namefolder=" + name}>
            <i className="fa fa-folder  fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text" >
              {name}
            </span>
          </a>
        </li>)}
        {archivos.map(name => <li key={name}>
          <a href={SubActivo !== "" ? process.env.REACT_APP_BACKEND_URL + "/descarga.php?folder=" + props.Nombre + '/' + SubActivo + "&filename=" + name :
            process.env.REACT_APP_BACKEND_URL + "/descarga.php?folder=" + props.Nombre + "&filename=" + name} target="_blank" >
            <i className="fa fa-file fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text">
              {name}
            </span>
          </a>
        </li>)}
      </ul>
    );
  }

  const cargarArchivo = async () => {
    try {
      var datos = document.getElementById("archivo").files[0];
      if (datos !== undefined) {

        const form_data = new FormData();
        form_data.append('sample_image', datos);

        const resp = await fetch(process.env.REACT_APP_BACKEND_URL + "/subir.php?folder=" + Activo, {
          method: "POST",
          body: form_data
        });
        const json = await resp.json();
        if (!alert(json)) { window.location.reload(); }
      }
      else {
        alert("Debe seleccionar un archivo");
      }



    } catch (error) {
      console.log(error);
    }
  };

  const modal = async () => {
    document.getElementById("modalOne").style.display = "block";

  };

  const close = async () => {
    document.getElementById("modalOne").style.display = "none";

  };
  const prueba = async () => {
    var datos = document.getElementById("archivo").files[0];

    var puntero = document.getElementsByClassName("file-upload-wrapper");
    puntero[0].removeAttribute('data-text');
    puntero[0].setAttribute('data-text', datos.name);

  };

  function ObtenerListadoSubidoUsuarioBiblioteca(props) {
    let carpetas = [];
    let archivos = [];

    if (Activo === (props.Nombre)) {

      const urlSearchParams = new URLSearchParams(window.location.search);
      const vari = urlSearchParams.get("namefolder");
      if (vari === "") {
        setSubActivoExt("");
      }
      for (const property in BibliotecaExt) {
        if (`${BibliotecaExt[property].type}` === "Carpeta") {
          carpetas.push(`${BibliotecaExt[property].name}`);
        }
        else {
          archivos.push(`${BibliotecaExt[property].name}`);
        }

      }
    }
    try {
      if (props.currentTarget.id !== undefined) {
        setSubActivoExt(props.currentTarget.id);
        cargarSubBibliotecaExt(process.env.REACT_APP_BACKEND_URL + "/cargarExt.php", props.currentTarget.id)
        for (const property in BibliotecaExt) {
          {
            archivos.push(`${BibliotecaExt[property].name}`);
          }

        }
      } else {

      }
    } catch (error) {
    }

    return (
      <ul className="directory-list listado">
        {SubActivoExt !== "" ? <li  >
          <a onClick={ObtenerListadoSubidoUsuarioBiblioteca} href="#"  >
            <i className="fa fa-arrow-circle-left  fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text" >
              volver
            </span>
          </a>
        </li> : null}
        {carpetas.map(name => <li key={name}>
          <a onClick={ObtenerListadoSubidoUsuarioBiblioteca} Nombre={Activo + '/' + name} id={name} href={"#?namefolder=" + name}>
            <i className="fa fa-folder  fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text" >
              {name}
            </span>
          </a>
        </li>)}
        {archivos.map(name => <li key={name}>
          <a href={SubActivoExt !== "" ? process.env.REACT_APP_BACKEND_URL + "/descargaExt.php?folder=" + props.Nombre + '/' + SubActivoExt + "&filename=" + name :
            process.env.REACT_APP_BACKEND_URL + "/descargaExt.php?folder=" + props.Nombre + "&filename=" + name} target="_blank" >
            <i className="fa fa-file fa-2x" style={{ 'width': '30px', 'fontSize': '16px', 'height': '20px' }}></i>
            <span className="nav-text">
              {name}
            </span>
          </a>
        </li>)}
      </ul>
    );
  }

  return (
    <>

      <MenuSidebar></MenuSidebar>
      <div className=" esquema" id="biblio" style={{ 'width': '100%', 'textAlign': '-webkit-right', 'minHeight': '-webkit-fill-available', 'paddingTop': '80px', 'visibility': 'hidden' }}>
        <div>
          <button className="button-7" role="button" id="btnCargar" style={{ 'float': 'right', 'visibility': 'hidden', 'marginRight': '20px', 'marginTop': '10px' }} onClick={modal} >
            <i className="fa fa-upload fa-2x" style={{ 'width': '10px', 'height': '10px', 'fontSize': '14px', 'display': 'initial' }}></i>
            <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }} > Subir Archivo</span>
          </button>
        </div>
        <div style={{ 'width': '83%', 'textAlign': 'initial' }}>
          <div className="tabset">

            <input type="radio" name="tabset" id="certificacion" aria-controls="certificacion" onClick={handleClick} href="#" />
            <label htmlFor="certificacion" id="certificacionlab">CERTIFICACIONES</label>

            <input type="radio" name="tabset" id="talleres" aria-controls="talleres" onClick={handleClick} href="#" />
            <label htmlFor="talleres" id="tallereslab">TALLERES</label>

            <input type="radio" name="tabset" id="ensayos" aria-controls="ensayos" onClick={handleClick} href="#" />
            <label htmlFor="ensayos" id="ensayoslab">ENSAYOS</label>

            <input type="radio" name="tabset" id="chas" aria-controls="chas" onClick={handleClick} href="#" />
            <label htmlFor="chas" id="chaslab">C.H.A.S.</label>

            <input type="radio" name="tabset" id="rtoe" aria-controls="chas" onClick={handleClick} href="#" />
            <label htmlFor="rtoe" id="rtoelab">RTOE</label>

            <div className="tab-panels">
              <section id="certificacion" className="tab-panel">
                <div className="box">
                  <ObtenerListadoBiblioteca Nombre="certificacion" />
                </div>
                <div className="box carga">
                  <span className="carga">
                    Documentos Cargados por usuarios
                  </span>
                </div>
                <div className="box">
                  <ObtenerListadoSubidoUsuarioBiblioteca Nombre="certificacion" />
                </div>
              </section>
              <section id="talleres" className="tab-panel">
                <div className="box">
                  <ObtenerListadoBiblioteca Nombre="talleres" />
                </div>
                <div className="box carga">
                  <span className="carga">
                    Documentos Cargados por usuarios
                  </span>
                </div>
                <div className="box">
                  <ObtenerListadoSubidoUsuarioBiblioteca Nombre="talleres" />
                </div>
              </section>
              <section id="ensayos" className="tab-panel">
                <div className="box">
                  <ObtenerListadoBiblioteca Nombre="ensayos" />
                </div>
                <div className="box carga">
                  <span className="carga">
                    Documentos Cargados por usuarios
                  </span>
                </div>
                <div className="box">
                  <ObtenerListadoSubidoUsuarioBiblioteca Nombre="ensayos" />
                </div>
              </section>
              <section id="chas" className="tab-panel">
                <div className="box">
                  <ObtenerListadoBiblioteca Nombre="chas" />
                </div>
                <div className="box carga">
                  <span className="carga">
                    Documentos Cargados por usuarios
                  </span>
                </div>
                <div className="box">
                  <ObtenerListadoSubidoUsuarioBiblioteca Nombre="chas" />
                </div>
              </section>
              <section id="rtoe" className="tab-panel">
                <div className="box">
                  <ObtenerListadoBiblioteca Nombre="rtoe" />
                </div>
                <div className="box carga">
                  <span className="carga">
                    Documentos Cargados por usuarios
                  </span>
                </div>
                <div className="box">
                  <ObtenerListadoSubidoUsuarioBiblioteca Nombre="rtoe" />
                </div>
              </section>
            </div>
            <div id="modalOne" className="modal">
              <div className="modal-content">
                <div className="contact-form">
                  <a className="close" onClick={close}>&times;</a>
                  <form id="frmNuevoArchivo" name="frmNuevoArchivo" className="exform" encType="multipart/form-data">
                    <div className="wrappermodal">
                      <div className="file-upload-wrapper one" data-text="...">
                        <input id="archivo" name="archivo" type="file" className="file-upload-field" onChange={prueba} />
                      </div>
                      <button type="button" id="btnNuevoArchivo" name="btnNuevoArchivo" onClick={cargarArchivo} className="button-7 two" style={{ 'marginRight': '30px' }}>Subir Archivo</button>
                    </div>

                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


    </>

  );
}
