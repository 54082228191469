import React from 'react';
import Select from 'react-select'
import DataTable from 'react-data-table-component';
import "../../assets/css/general.css";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const columns = [
  {
    name: 'Empleado',
    selector: row => row.usuario,
  },
  {
    name: 'Nombre',
    selector: row => row.nombre,
  },
  {
    name: 'Apellido',
    selector: row => row.apellidos,
  },
  {
    name: 'Perfiles',
    selector: row => row.perfiles,
  },
];

function EmpleadosTable() {


  const [Activo, setActivo] = React.useState([]);
  const [Accion, setAccion] = React.useState("");
  const [Empleados, setEmpleados] = React.useState([]);
  const [Perfiles, setPerfiles] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState();

  let SelectComponent = Seleccion;

  React.useEffect(() => {
    if (Empleados.length === 0) {
      try {
        cargarEmpleados(process.env.REACT_APP_BACKEND_URL + "/obtenerEmpleados.php");
      } catch (error) {

      }
    }
    if (Perfiles.length === 0) {
      try {
        cargarPerfiles(process.env.REACT_APP_BACKEND_URL + "/obtenerPerfilesSelect.php");
      } catch (error) {

      }
    }
  }, [Empleados, Perfiles])

  const cargarEmpleados = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await resp.json();
      setEmpleados(json);

    } catch (error) {
      console.log(error);
    }
  };

  const cargarPerfiles = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await resp.json();
      setPerfiles(json);

    } catch (error) {
      console.log(error);
    }
  };

  const insertarEmpleado = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'usuario': document.getElementById("username").value
          , 'contraseña': document.getElementById("password").value
          , 'nombre': document.getElementById("name").value
          , 'apellidos': document.getElementById("surname").value
          , 'idAlta': auth.id
          , 'perfiles' : selectedOptions
        }),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };

  const actualizarEmpleado = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'usuario': document.getElementById("username").value
          , 'contraseña': document.getElementById("password").value
          , 'nombre': document.getElementById("name").value
          , 'apellidos': document.getElementById("surname").value
          , 'idAlta': auth.id
          , 'id': document.getElementById("idUser").value
          , 'perfiles' : selectedOptions
        }),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };
  
  const eliminarEmpleado = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'id': document.getElementById("idUser").value
        , 'idAlta': auth.id}),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (state) => {
    if (state.selectedRows.length) {
      document.getElementById("idUser").value = state.selectedRows[0].id;
      document.getElementById("username").value = state.selectedRows[0].usuario;
      document.getElementById("name").value = state.selectedRows[0].nombre;
      document.getElementById("surname").value = state.selectedRows[0].apellidos;
      try {
        var arrayDeCadenas = state.selectedRows[0].perfiles.split(",");
        var arrayDeId = state.selectedRows[0].id_perfiles.split(",");
        let perfilesDefault = [];
        for (var i = 0; i < arrayDeId.length; i++) {
          let perfil = {
            "value": parseInt(arrayDeId[i]),
            "label": arrayDeCadenas[i]
          }
          perfilesDefault.push(perfil);
        }
        setActivo(perfilesDefault);
        setSelectedOptions(perfilesDefault);
      } catch (error) {
        let perfilesDefault = [];
        setActivo(perfilesDefault);
        setSelectedOptions(perfilesDefault);
      }
      document.getElementById("ocultarUsuario").style.visibility = "visible";
      setAccion("Modificar Empleado");
    }
    else {
      document.getElementById("ocultarUsuario").style.visibility = "hidden";
    }
  };

  const handleClick = () => {
    document.getElementById("idUser").value = 0;
    document.getElementById("username").value = "";
    document.getElementById("name").value = "";
    document.getElementById("surname").value = "";
    setSelectedOptions([]);
    document.getElementById("ocultarUsuario").style.visibility = "visible";
    setAccion("Crear Empleado");
  };

  const handleClickSave = (event) => {
    event.preventDefault();
    if(parseInt(document.getElementById("idUser").value)>0 )
      actualizarEmpleado(process.env.REACT_APP_BACKEND_URL + "/actualizarEmpleados.php");
    else
      insertarEmpleado(process.env.REACT_APP_BACKEND_URL + "/insertarEmpleados.php");

  };

  const handleClickCancel = (event) => {
    event.preventDefault();
    document.getElementById("ocultarUsuario").style.visibility = "hidden";
  };

  const handleClickDel = (event) => {
    event.preventDefault();
    eliminarEmpleado(process.env.REACT_APP_BACKEND_URL + "/eliminarEmpleados.php");
  };


  function handleSelect(data) {
    setSelectedOptions(data);
  }

  function Seleccion(props) {
    return <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      isMulti
      options={Perfiles}
      defaultValue={Activo}
      value={selectedOptions}
      onChange={handleSelect}
      placeholder={'Seleccione Perfiles...'}
    />;

  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <>
      <div>
        <h5 className="five" >Empleados</h5>
        <button className="button-7" role="button" onClick={handleClick}>
          <i className="fa fa-user-plus fa-2x buttonFa" ></i>
          <span className="buttonSpan" > Agregar Empleado</span>
        </button>
      </div>

      <DataTable
        columns={columns}
        data={Empleados}
        selectableRows
        selectableRowsSingle
        onSelectedRowsChange={handleChange}
        paginationComponentOptions={paginationComponentOptions}
        pagination
      />

      <div style={{ 'textAlign': 'initial', 'paddingLeft': '20px', 'fontFamily': 'Poppins', 'fontSize': '12px', 'visibility': 'hidden' }} id="ocultarUsuario">
        <h5 style={{ 'textAlign': 'initial', 'paddingLeft': '10px', 'paddingBottom': '10px', 'fontSize': '14px' }}>{Accion}</h5>
        <form className='form-style-1' >
          <div style={{ 'visibility': 'hidden', 'height': '0px', 'width': '0px' }}>
            <input type="idUser" name="idUser" id="idUser" defaultValue="0" />
          </div>
          <div className='wrapper'>
            <div >
              <label htmlFor="username" >Empleado</label>
              <input type="username" name="username" id="username" />
            </div>
            <div >
              <label htmlFor="password">Contraseña</label>
              <input type="password" name="password" id="password" autoComplete='new-password' />
            </div>
            <div >
              <label htmlFor="name" >Nombre</label>
              <input type="name" name="name" id="name" />
            </div>
            <div >
              <label htmlFor="surname" >Apellido</label>
              <input type="surname" name="surname" id="surname" />
            </div>
            <div >
              <label htmlFor="profile" >Perfiles</label>
              <SelectComponent id="profile" />
            </div>

          </div>
          <div style={{ 'paddingTop': '10px', 'paddingLeft': '0px' }}>
            <button className="button-7" role="button" onClick={handleClickSave} style={{ 'marginLeft': '0px' }}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Guardar</span>
            </button>
            <button className="button-7" role="button" onClick={handleClickCancel}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Cancelar</span>
            </button>
            <button className="button-8" role="button" onClick={handleClickDel}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Eliminar </span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};



export default EmpleadosTable;
