import React from 'react';
import DataTable from 'react-data-table-component';
import "../../assets/css/general.css";

const columns = [
    
    {
        name: 'Perfil',
        selector: row => row.etiquetaTipoUsuario,
    },
    {
        name: 'Pantalla',
        selector: row => row.nombre,
    },
];

function AuditoriasTable() {
    const [Accion, setAccion] = React.useState("");
    const [Auditorias, setAuditorias] = React.useState([]);

    const cargarAuditorias = async (url) => {
      try {
        
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const json = await resp.json();
        setAuditorias(json);
      } catch (error) {
        console.log(error);
      }
    }
  
    React.useEffect(() => {
      if (Auditorias.length === 0) {
        try {
            cargarAuditorias(process.env.REACT_APP_BACKEND_URL + "/obtenerAuditorias.php");
        } catch (error) {
  
        }
      }
    
    }, [Auditorias])
  
    const handleChange = (state) => {
        if(state.selectedRows.length){
            document.getElementById("idRel").value = state.selectedRows[0].idRel;
            document.getElementById("name").value = state.selectedRows[0].etiquetaTipoUsuario;
            document.getElementById("pant").value = state.selectedRows[0].nombre;
            document.getElementById("ocultar").style.visibility= "visible";
            setAccion("Modificar Auditoria");
        }
        else{
            document.getElementById("ocultar").style.visibility= "hidden"; 
        }
      };
    
        
    const handleClick = () => {

        document.getElementById("idRel").value = 0;
        document.getElementById("name").value = "";
        document.getElementById("pant").value = "";
        document.getElementById("ocultar").style.visibility= "visible";
        setAccion("Crear Auditoria");
    
      };

            
    const handleClickSave = (event) => {
        event.preventDefault();
        alert("Se ha guardado correctamente.");
      };
    
      const handleClickCancel = (event) => {
        event.preventDefault();
        document.getElementById("ocultar").style.visibility= "hidden"; 
      };
    
    return (
        <>
        <div>
        <h5 style={{'textAlign':'initial','fontFamily':'Poppins','paddingLeft':'30px','paddingBottom':'14px','fontSize':'14px'}}>Auditorias</h5>   
    
        </div>
        
        <DataTable            
            columns={columns}
            data={Auditorias}
            selectableRows 
            selectableRowsSingle
            onSelectedRowsChange={handleChange}
            noDataComponent="No existen registros en Auditoria."
        />
        <div style={{'textAlign':'initial','paddingLeft':'20px','fontFamily':'Poppins','fontSize':'12px','visibility':'hidden'}} id="ocultar">
            
            <h5 style={{'textAlign':'initial','paddingLeft':'10px','paddingBottom':'10px','fontSize':'14px'}}>{Accion}</h5>
            <form className='form-style-1' >
                <div >
                    <input type="idRel" name="idRel" id="idRel" style={{'visibility':'hidden'}} defaultValue="0" />
                </div>
                <div >
                    <label htmlFor="name" >Perfil</label>
                    <input type="name" name="name" id="name"  />
                </div>  
                <div >
                    <label htmlFor="pant" >Pantalla</label>
                    <input type="pant" name="pant" id="pant"  />
                </div>    
                <div style={{'paddingTop':'10px','paddingLeft':'0px'}}>
                    <button className="button-7" role="button" onClick={handleClickSave} style={{'marginLeft':'0px'}}> 
                        <span  style={{'fontFamily':'Poppins','fontSize':'12px','display':'initial'}}>Guardar</span>
                    </button>
                    <button className="button-7" role="button" onClick={handleClickCancel}>
                        <span  style={{'fontFamily':'Poppins','fontSize':'12px','display':'initial'}}>Cancelar</span>
                    </button>
                </div>
            </form>
        </div>
        </>
        
    );
};

export default AuditoriasTable;
