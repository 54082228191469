import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/menuSidebar.css";

const pantallas = [ "usuarios", "perfiles", "permisos", "biblioteca", "auditoria","empleados"];

const MenuSidebar = (props) => {

    const navigate = useNavigate();
    const [PantallasPermisos, setPantallasPermisos] = React.useState([]);
    const [Nombre, setNombre] = React.useState("test");
    const cargarPerfil = async (url) => {
      try {
        var retrievedObject = localStorage.getItem('auth');
        const auth = JSON.parse(retrievedObject);
        setNombre(auth.nombre + " " + auth.apellidos);
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          'body':JSON.stringify({'perfil':auth.id}),
        });
        const json = await resp.json();
        pantallas.map(function(element) {
            if(json.find( arreglo => arreglo['nombre'] === element )){
            } else 
            {
                try {
                    const elemento = document.getElementById(element);
                    elemento.remove();        
                } catch (error) {
                    
                }
            }
        }
        );
        document.getElementById("menu").style.visibility = "visible";
      } catch (error) {
        console.log(error);
      }
    }
  
    React.useEffect(() => {
      if (PantallasPermisos.length === 0) {
        try {
          cargarPerfil(process.env.REACT_APP_BACKEND_URL + "/obtenerPermisosUsuario.php");
        } catch (error) {
  
        }
      }
    
    }, [PantallasPermisos])
  
    function miFunc() {

        localStorage.clear();
        navigate("/");
      }

  return (

<nav className="main-menu" id="menu" style={{'position':'fixed'}}>
    <div style={{'fontFamily':'Poppins','fontSize':'20px','textAlign':'center','paddingTop':'30px'}}> Hola {Nombre}</div>
            <ul>
                <li>
                    <a href="/Dashboard">
                        <i className="fa fa-home fa-2x"></i>
                        <span className="nav-text">
                            Inicio
                        </span>
                    </a>
                  
                </li>
                <li id="empleados">
                    <a href="/Dashboard/Employers">
                        <i className="fa fa-users fa-2x"></i>
                        <span className="nav-text">
                            Empleados
                        </span>
                    </a>
                </li>
                <li id="perfiles">
                    <a href="/Dashboard/Profile">
                        <i className="fa fa-user-gear fa-2x"></i>
                        <span className="nav-text">
                            Perfiles
                        </span>
                    </a>
                </li>
                <li id="permisos"> 
                    <a href="/Dashboard/PermissionScreen">
                        <i className="fa fa-pen fa-2x"></i>
                        <span className="nav-text">
                            Permisos Pantallas
                        </span>
                    </a>
                </li>
                <li id="usuarios">
                    <a href="/Dashboard/Users">
                        <i className="fa fa-user fa-2x"></i>
                        <span className="nav-text">
                            Usuarios
                        </span>
                    </a>
                </li>
                <li id="permisos"> 
                    <a href="/Dashboard/PermissionLibrary">
                        <i className="fa fa-pen fa-2x"></i>
                        <span className="nav-text">
                            Permisos Biblioteca
                        </span>
                    </a>
                </li>
                <li id="biblioteca">
                    <a href="/Dashboard/Library">
                       <i className="fa fa-folder-open fa-2x"></i>
                        <span className="nav-text">
                            Biblioteca
                        </span>
                    </a>
                </li>
                <li id="auditoria">
                    <a href="/Dashboard/Audit">
                       <i className="fa fa-shield fa-2x"></i>
                        <span className="nav-text">
                            Auditoria
                        </span>
                    </a>
                </li>
            </ul>

            <ul className="logout">
                <li>
                   <a  onClick={miFunc}>
                         <i className="fa fa-power-off fa-2x"></i>
                        <span className="nav-text">
                            Logout
                        </span>
                    </a>
                </li>  
            </ul>
        </nav>
  );
};

export default MenuSidebar;

