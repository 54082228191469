import React from 'react';
import DataTable from 'react-data-table-component';
import "../../assets/css/general.css";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const columns = [

  {
    name: 'Perfil',
    selector: row => row.etiquetaTipoUsuario,
  },
  {
    name: 'Biblioteca',
    selector: row => row.nombre,
  },
];

function PermisosBibliotecaTable() {
  const [ActivoPerfil, setActivoPerfil] = React.useState([]);
  const [ActivoBiblioteca, setActivoBiblioteca] = React.useState([]);
  const [Accion, setAccion] = React.useState("");
  const [Permisos, setPermisos] = React.useState([]);
  const [Perfiles, setPerfiles] = React.useState([]);
  const [Biblioteca, setBiblioteca] = React.useState([]);
  const [selectedOptionsPerfiles, setSelectedOptionsPerfiles] = React.useState();
  const [selectedOptionsBiblioteca, setSelectedOptionsBiblioteca] = React.useState();

  let SelectComponentPerfiles = SeleccionPerfiles;
  let SelectComponentBiblioteca = SeleccionBiblioteca;

  React.useEffect(() => {
    if (Permisos.length === 0) {
      try {
        cargarPermisos(process.env.REACT_APP_BACKEND_URL + "/obtenerPermisosBiblotecaConf.php");
      } catch (error) {

      }
    }
    if (Perfiles.length === 0) {
      try {
        cargarPerfiles(process.env.REACT_APP_BACKEND_URL + "/obtenerPerfilesSelect.php");
      } catch (error) {

      }
    }
    if (Biblioteca.length === 0) {
      try {
        cargarBiblioteca(process.env.REACT_APP_BACKEND_URL + "/obtenerBibliotecaSelect.php");
      } catch (error) {

      }
    }
  }, [Permisos, Perfiles, Biblioteca])

  const cargarPermisos = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await resp.json();
      setPermisos(json);
    } catch (error) {
      console.log(error);
    }
  }

  const cargarPerfiles = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await resp.json();
      setPerfiles(json);

    } catch (error) {
      console.log(error);
    }
  };

  const cargarBiblioteca = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await resp.json();
      setBiblioteca(json);

    } catch (error) {
      console.log(error);
    }
  };

  const insertarPermiso = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');

      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'perf': parseInt(selectedOptionsPerfiles.value) 
          , 'bibl':parseInt(selectedOptionsBiblioteca.value)
          , 'idAlta': auth.id
        }),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarPermiso = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'id': document.getElementById("idRel").value
          , 'idAlta': auth.id
        }),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (state) => {
    if (state.selectedRows.length) {
      document.getElementById("idRel").value = state.selectedRows[0].idRel;

      try {
        let perfilesDefault = [];
        let perfil = {
          "value": state.selectedRows[0].idTipoUsuario,
          "label": state.selectedRows[0].etiquetaTipoUsuario
        }
        perfilesDefault.push(perfil);

        setActivoPerfil(perfilesDefault);
        setSelectedOptionsPerfiles(perfilesDefault);
      } catch (error) {
        console.log(error);

      }

      try {
        let BibliotecaDefault = [];
        let Biblioteca = {
          "value": state.selectedRows[0].idPan,
          "label": state.selectedRows[0].nombre
        }
        BibliotecaDefault.push(Biblioteca);

        setActivoBiblioteca(BibliotecaDefault);
        setSelectedOptionsBiblioteca(BibliotecaDefault);
      } catch (error) {
        console.log(error);
      }
      
      document.getElementById("ocultarSave").style.visibility = "hidden";
      document.getElementById("ocultar").style.visibility = "visible";
      setAccion("Modificar Permiso");
    }
    else {

      document.getElementById("ocultar").style.visibility = "hidden";
    }
  };

  const handleClick = () => {

    document.getElementById("idRel").value = 0;
    setSelectedOptionsPerfiles([]);
    setSelectedOptionsBiblioteca([]);
    document.getElementById("ocultar").style.visibility = "visible";
    document.getElementById("ocultarSave").style.visibility = "visible";
    setAccion("Crear Permiso");

  };

  const handleClickSave = (event) => {
    event.preventDefault();
    insertarPermiso(process.env.REACT_APP_BACKEND_URL + "/insertarPermisoBiblioteca.php");
  };

  const handleClickCancel = (event) => {
    event.preventDefault();
    document.getElementById("ocultar").style.visibility = "hidden";
    document.getElementById("ocultarSave").style.visibility = "hidden";
  };

  const handleClickDel = (event) => {
    event.preventDefault();
    eliminarPermiso(process.env.REACT_APP_BACKEND_URL + "/eliminarPermisoBiblioteca.php");
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  function SeleccionPerfiles(props) {
    return <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      options={Perfiles}
      defaultValue={ActivoPerfil}
      value={selectedOptionsPerfiles}
      onChange={handleSelectPerfil}
      placeholder={'Seleccione Perfil...'}
    />;
  }

  function SeleccionBiblioteca(props) {
    return <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      options={Biblioteca}
      defaultValue={ActivoBiblioteca}
      value={selectedOptionsBiblioteca}
      onChange={handleSelectBiblioteca}
      placeholder={'Seleccione Biblioteca...'}
    />;
  }


  function handleSelectPerfil(data) {
    setSelectedOptionsPerfiles(data);
  }

  function handleSelectBiblioteca(data) {
    setSelectedOptionsBiblioteca(data);
  }


  return (
    <>
      <div>
        <h5 className="five">Configurar Permisos Biblioteca </h5>
        <button className="button-7" role="button" onClick={handleClick}>
          <i className="fa fa-user-plus fa-2x buttonFa" ></i>
          <span className="buttonSpan" > Agregar Permiso</span>
        </button>
      </div>
      <DataTable
        columns={columns}
        data={Permisos}
        selectableRows
        selectableRowsSingle
        onSelectedRowsChange={handleChange}
        paginationComponentOptions={paginationComponentOptions}
        pagination
      />
      <div style={{ 'textAlign': 'initial', 'paddingLeft': '20px', 'fontFamily': 'Poppins', 'fontSize': '12px', 'visibility': 'hidden' }} id="ocultar">
        <h5 style={{ 'textAlign': 'initial', 'paddingLeft': '10px', 'paddingBottom': '10px', 'fontSize': '14px' }}>{Accion}</h5>
        <form className='form-style-1' >
          <div style={{ 'visibility': 'hidden', 'height': '0px', 'width': '0px' }}>
            <input type="idRel" name="idRel" id="idRel" style={{ 'visibility': 'hidden' }} defaultValue="0" />
          </div>
          <div className='wrapper'>
            <div >
              <label htmlFor="name" >Perfil</label>
              <SelectComponentPerfiles id="perf" />
            </div>
            <div >
              <label htmlFor="bibl" >Biblioteca</label>
              <SelectComponentBiblioteca id="bibl" />
            </div>
          </div>          
          <div style={{ 'paddingTop': '10px', 'paddingLeft': '0px' }}>
          <div id="ocultarSave" style={{ 'visibility': 'hidden' }}>
          <button className="button-7" role="button" onClick={handleClickSave} style={{ 'marginLeft': '0px' }} >
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Guardar</span>
            </button>
            <button className="button-7" role="button" onClick={handleClickCancel}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Cancelar</span>
            </button>
            
            </div>
            <button className="button-8" role="button" onClick={handleClickDel}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Eliminar </span>
            </button>
          </div>
        </form>
      </div>
    </>

  );
};

export default PermisosBibliotecaTable;
