/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import MenuSidebar from '../../components/sidebar/menuSidebar';
import AuditoriasTable from "../../components/tables/auditoriasTable";
export default function Biblioteca() {

  return (
    <>
    
    <MenuSidebar></MenuSidebar>
    <div  style={{'width':'100%','textAlign':'-webkit-right','minHeight':'-webkit-fill-available','paddingTop':'80px' }}>
      <div style={{'width':'83%'}}>
         <AuditoriasTable></AuditoriasTable>
      </div>
    </div>
 </>
    
  );
}
