import React from 'react';
import DataTable from 'react-data-table-component';
import "../../assets/css/general.css";

const columns = [

  {
    name: 'Nombre Perfil',
    selector: row => row.etiquetaTipoUsuario,
  },
  {
    name: 'Descripción',
    selector: row => row.descripcionTipoUsuario,
  },
];

function PerfilesTable() {
  const [Accion, setAccion] = React.useState("");
  const [Perfiles, setPerfiles] = React.useState([]);

  React.useEffect(() => {
    if (Perfiles.length === 0) {
      try {
        cargarPerfiles(process.env.REACT_APP_BACKEND_URL + "/obtenerPerfiles.php");
      } catch (error) {

      }
    }

  }, [Perfiles])


  const cargarPerfiles = async (url) => {
    try {

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await resp.json();
      setPerfiles(json);
    } catch (error) {
      console.log(error);
    }
  }

  const insertarPerfil = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'nombre': document.getElementById("name").value
          , 'descripcion': document.getElementById("desc").value
          , 'idAlta': auth.id
        }),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };

  const actualizarPerfil = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'nombre': document.getElementById("name").value
          , 'descripcion': document.getElementById("desc").value
          , 'idAlta': auth.id
          , 'id': document.getElementById("idProfile").value
        }),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarPerfil = async (url) => {
    try {
      var retrievedObject = localStorage.getItem('auth');
      const auth = JSON.parse(retrievedObject);
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'id': document.getElementById("idProfile").value
          , 'idAlta': auth.id
        }),
      });
      const json = await resp.json();
      if (!alert(json)) { window.location.reload(); }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (state) => {
    if (state.selectedRows.length) {
      document.getElementById("idProfile").value = state.selectedRows[0].idTipoUsuario;
      document.getElementById("name").value = state.selectedRows[0].etiquetaTipoUsuario;
      document.getElementById("desc").value = state.selectedRows[0].descripcionTipoUsuario;
      document.getElementById("ocultar").style.visibility = "visible";
      setAccion("Modificar Perfil");
    }
    else {
      document.getElementById("ocultar").style.visibility = "hidden";
    }
  };

  const handleClick = () => {

    document.getElementById("idProfile").value = 0;
    document.getElementById("name").value = "";
    document.getElementById("desc").value = "";
    document.getElementById("ocultar").style.visibility = "visible";
    setAccion("Crear Perfil");
  };

  const handleClickSave = (event) => {
    event.preventDefault();
    if (parseInt(document.getElementById("idProfile").value) > 0)
      actualizarPerfil(process.env.REACT_APP_BACKEND_URL + "/actualizarPerfiles.php");
    else
      insertarPerfil(process.env.REACT_APP_BACKEND_URL + "/insertarPerfiles.php");

  };

  const handleClickCancel = (event) => {
    event.preventDefault();
    document.getElementById("ocultar").style.visibility = "hidden";
  };

  const handleClickDel = (event) => {
    event.preventDefault();
    eliminarPerfil(process.env.REACT_APP_BACKEND_URL + "/eliminarPerfiles.php");
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <>
      <div>
        <h5 className="five">Perfiles</h5>
        <button className="button-7" role="button" onClick={handleClick}>
          <i className="fa fa-user-plus fa-2x buttonFa" ></i>
          <span className="buttonSpan" > Agregar Perfil</span>
        </button>
      </div>

      <DataTable
        columns={columns}
        data={Perfiles}
        selectableRows
        selectableRowsSingle
        onSelectedRowsChange={handleChange}
        paginationComponentOptions={paginationComponentOptions}
        pagination
      />

      <div style={{ 'textAlign': 'initial', 'paddingLeft': '20px', 'fontFamily': 'Poppins', 'fontSize': '12px', 'visibility': 'hidden' }} id="ocultar">
        <h5 style={{ 'textAlign': 'initial', 'paddingLeft': '10px', 'paddingBottom': '10px', 'fontSize': '14px' }}>{Accion}</h5>
        <form className='form-style-1' >
          <div style={{ 'visibility': 'hidden', 'height': '0px', 'width': '0px' }}>
            <input type="idProfile" name="idProfile" id="idProfile" style={{ 'visibility': 'hidden' }} defaultValue="0" />
          </div>
          <div className='wrapper'>
            <div >
              <label htmlFor="name" >Nombre</label>
              <input type="name" name="name" id="name" />
            </div>
            <div >
              <label htmlFor="desc" >Descripción</label>
              <input type="desc" name="desc" id="desc"  />
            </div>
          </div>
          <div style={{ 'paddingTop': '10px', 'paddingLeft': '0px' }}>
            <button className="button-7" role="button" onClick={handleClickSave} style={{ 'marginLeft': '0px' }}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Guardar</span>
            </button>
            <button className="button-7" role="button" onClick={handleClickCancel}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Cancelar</span>
            </button>
            <button className="button-8" role="button" onClick={handleClickDel}>
              <span style={{ 'fontFamily': 'Poppins', 'fontSize': '12px', 'display': 'initial' }}>Eliminar </span>
            </button>
          </div>
        </form>
      </div>
   
    </>

  );
};

export default PerfilesTable;
