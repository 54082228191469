/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import MenuSidebar from '../../components/sidebar/menuSidebar';
import UsuariosTable from "../../components/tables/usuariosTable";
export default function Empleados() {

  return (
    <>
    
    <MenuSidebar></MenuSidebar>
    <div  className="esquema" >
      <div style={{'width':'83%'}}>
         <UsuariosTable></UsuariosTable>
      </div>
    </div>


 </>
    
  );
}
