import './App.css';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Login from './views/Login.js';
import Auditoria from './views/dashboard/Auditoria';
import Biblioteca from './views/dashboard/Biblioteca';
import Index from './views/dashboard/Index';
import Perfiles from './views/dashboard/Perfiles';
import PermisosPantallas  from './views/dashboard/PermisosPantallas';
import PermisosBiblioteca   from './views/dashboard/PermisosBiblioteca';
import Empleados from './views/dashboard/Empleados';
import Usuarios from './views/dashboard/Usuarios';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/Dashboard'element={<Index />} />
        <Route path='/Dashboard/Employers'element={<Empleados />} />
        <Route path='/Dashboard/Users'element={<Usuarios />} />
        <Route path='/Dashboard/Library'element={<Biblioteca />} />
        <Route path='/Dashboard/PermissionScreen'element={<PermisosPantallas />} />
        <Route path='/Dashboard/PermissionLibrary'element={<PermisosBiblioteca />} />
        <Route path='/Dashboard/Profile'element={<Perfiles />} />
        <Route path='/Dashboard/Audit'element={<Auditoria />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
