/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import MenuSidebar from '../../components/sidebar/menuSidebar';
import PerfilesTable from "../../components/tables/perfilesTable";
export default function Perfiles() {

  return (
    <>
    <MenuSidebar></MenuSidebar>
    <div  className="esquema" >
      <div style={{'width':'83%'}}>
         <PerfilesTable></PerfilesTable>
      </div>
    </div>
    </>   
  );
}
