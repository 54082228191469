/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import MenuSidebar from '../../components/sidebar/menuSidebar';
import PermisosPantallasTable from "../../components/tables/permisosPantallasTable";
export default function Permisos() {

  return (
    <>
    <MenuSidebar></MenuSidebar>
    <div  className="esquema">
      <div style={{'width':'83%'}}>
         <PermisosPantallasTable></PermisosPantallasTable>
      </div>
    </div>

 </>
    
  );
}
